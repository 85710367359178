<template>
  <section class="page-content">
    <FilteredProducts />
  </section>
</template>

<script>
import FilteredProducts from "@/esf_antarctica_feestenkleding/core/components/category-product/FilteredProducts.vue";
export default {
  name: "CategoryPageContent",
  components: {
    FilteredProducts,
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  font-size: 16px;
}
</style>
