<template>
  <div class="product-card--holder">
    <NotifyModal
      :show="showNotifyModal"
      :product-sku="product.sku"
      @close="closeNotifyModal"
    />

    <!-- model -->
    <b-modal
      :id="`modal-${product.sku}`"
      v-model="addToComparePopup"
      size="lg"
      content-class="product-popup"
      centered
      :hide-footer="true"
    >
      <div class="product-popup-body">
        <img :src="product.thumbnail.url" />

        <div class="info-popup">
          <h2>{{ product.name }}</h2>
          <div class="info-popup-wrap">
            <p class="my-price">
              <span
                v-if="
                  product.price_range.minimum_price.final_price.value ==
                  product.price_range.maximum_price.final_price.value
                "
                class="d-block product-card--price"
                >{{
                  formatCurrency(
                    product.price_range.minimum_price.final_price.value
                  )
                }}</span
              >
              <span
                v-if="
                  product.price_range.minimum_price.final_price.value !=
                  product.price_range.maximum_price.final_price.value
                "
                class="d-block product-card--price"
                >{{
                  formatCurrency(
                    product.price_range.minimum_price.final_price.value
                  )
                }}
                -
                {{
                  formatCurrency(
                    product.price_range.maximum_price.final_price.value
                  )
                }}</span
              >
              <!-- <span
                class="d-block product-card--sale-comment"
                v-if="
                  product.price_range.minimum_price.discount.percent_off > 0
                "
                >{{ $t("discount") }}
                {{
                  product.price_range.minimum_price.discount.percent_off.toFixed(
                    0
                  )
                }}%</span
              > -->
            </p>
            <div class="action-btn">
              <b-link @click="clickOk" class="c-btn c-btn-primary">{{
                $t("compare_product")
              }}</b-link>
              <b-link @click="clickCancel" class="btn-link">
                <b-icon icon="chevron-left"></b-icon> Doorgaan met
                winkelen</b-link
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- model -->

    <div class="product-card">
      <div class="product-img-wrap">
        <b-link @click="toggleWishItem" class="add-to-wishlist">
          <div v-if="$store.getters['user/isProductInWishlist'](product.sku)">
            <b-icon icon="heart-fill" class="added"></b-icon>
          </div>
          <b-icon icon="heart" class="not-added" v-else></b-icon>
        </b-link>
        <b-link :to="`/${product.url_key}`" class="d-block product-img-link">
          <div
            class="product-card--img-top"
            :style="`background-image: url('` + productImg + `');`"
          >
            <div class="actionbuttons">
              <span class="sale-txt" v-if="onSale == true">Aanbieding</span>
              <div class="new-txt" v-if="onNew == true">Nieuw</div>
            </div>
          </div>
          <!-- <div class="out-stock-block" v-if="!productInStock">
            <span>OUT OF STOCK</span>
          </div> -->
        </b-link>
      </div>

      <div class="product-extra">
        <div class="first-info">
          <!-- <label style="color:#06dfc1; font-weight:600">{{product.__typename}}</label> -->
          <div class="name">
            <b-link :to="`/${product.url_key}`"
              ><div class="product-card--product-name">
                {{ product.name }}
              </div></b-link
            >
          </div>
          <div class="d-block product-model">
            <span>
              <span class="bold">{{ productBrand }}</span>
              &nbsp;<span>{{ productModel }}</span>
            </span>
          </div>
          <div class="lv">
            <Rating
              :stars="product.rating_summary"
              :noOfRatings="product.review_count"
            />
            <div class="d-block delivery-info mb-20 card-delivery-info">
              <span
                v-if="product.DeliveryTime != null"
                :style="'color: ' + product.DeliveryTime.color + '!important'"
              >
                {{ product.DeliveryTime.short }}
              </span>
              <!-- {{ $t("delivery_time") }}:  -->
            </div>
            <label class="stock-status" :class="stockStatusClass"
              >{{ $t(productInStock ? "In Stock" : "Out of Stock") }}
            </label>
          </div>
        </div>

        <div class="bottombox">
          <div class="gv">
            <Rating
              :stars="product.rating_summary"
              :noOfRatings="product.review_count"
            />
            <div class="d-block delivery-info mb-20 card-delivery-info">
              <span
                v-if="product.DeliveryTime != null"
                :style="'color: ' + product.DeliveryTime.color + '!important'"
              >
                {{ product.DeliveryTime.short }}
              </span>
              <!-- {{ $t("delivery_time") }}:  -->
            </div>
            <label class="stock-status" :class="stockStatusClass"
              >{{ $t(productInStock ? "In Stock" : "Out of Stock") }}
            </label>
          </div>
          <div class="price-cart">
            <div class="price-group">
              <div
                class="price-wrap"
                v-if="
                  product.price_range.minimum_price.final_price.value ==
                  product.price_range.maximum_price.final_price.value
                "
              >
                <div
                  v-if="
                    product.price_range.minimum_price.regular_price.value >
                    product.price_range.minimum_price.final_price.value
                  "
                  class="normal-price"
                >
                  {{
                    formatCurrency(
                      product.price_range.minimum_price.regular_price.value
                    )
                  }}
                </div>
                <span class="final-price product-card--price">{{
                  formatCurrency(
                    product.price_range.minimum_price.final_price.value
                  )
                }}</span>
              </div>

              <span
                v-if="
                  product.price_range.minimum_price.final_price.value !=
                  product.price_range.maximum_price.final_price.value
                "
                class="d-block product-card--price"
                >{{
                  formatCurrency(
                    product.price_range.minimum_price.final_price.value
                  )
                }}
                -
                {{
                  formatCurrency(
                    product.price_range.maximum_price.final_price.value
                  )
                }}</span
              >

              <!-- <span
                class="d-block product-card--sale-comment"
                v-if="
                  product.price_range.minimum_price.discount.percent_off > 0
                "
                >{{ $t("discount") }}
                {{
                  product.price_range.minimum_price.discount.percent_off.toFixed(
                    0
                  )
                }}%</span
              > -->
            </div>
            <div
              v-if="productInStock"
              class="cart-btn"
              @click="addToCart"
              key="cart-btn"
            >
              <i class="fas fa-shopping-bag"></i>
              <span>{{ $t("add_to_cart") }}</span>
            </div>
            <div v-else class="cart-btn" @click="addToNotify" key="notify-btn">
              <i class="fas fa-envelope"></i>
              <span>{{ $t("stock_update") }}</span>
            </div>
          </div>
          <div class="compare-part custom-control custom-checkbox">
            <input
              :id="viewType + 'compare' + product.sku"
              type="checkbox"
              class="custom-control-input"
              @click="onClickCompareCheckBox"
              :checked="isProductInCompare"
            />
            <label class="custom-control-label">
              {{ $t("compare") }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "@/base/core/components/category-product/Rating.vue";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

import loaderImg from "@/base/coreassets/loader.gif";
import loaderErrorImg from "@/base/coreassets/loadererror.gif";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      require: true,
    },
    viewType: {
      type: String,
      default: "grid-view",
      require: false,
    },
  },
  created() {},
  data() {
    return {
      loaderImg: loaderImg,
      loaderErrorImg: loaderErrorImg,
      isCompateChecked: true,
      addToComparePopup: false,
      showNotifyModal: false,
    };
  },
  components: {
    Rating,
    NotifyModal,
    //VueLazyBackgroundImage,
  },
  computed: {
    ...mapGetters({
      compareList: "compare/getProductCompare",
      cartItems: "cart/getCartItems",
    }),
    productBrand() {
      let productBrand = "";
      if (this.product.information_attributes) {
        const brand = this.product.information_attributes.find(
          (item) => item.attribute_code == "merk"
        );
        if (brand) {
          productBrand = brand.value;
        }
      }
      return productBrand;
    },
    productModel() {
      let productModel = "";
      if (this.product.information_attributes) {
        const model = this.product.information_attributes.find(
          (item) => item.attribute_code == "model_number"
        );
        if (model) {
          productModel = model.value;
        }
      }
      return productModel;
    },

    onSale() {
      /*
      const today = new Date();
      if (this.product.special_from_date != null) {
        if (new Date(this.product.special_from_date) <= today) {
          if (new Date(this.product.special_to_date) >= today) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
      */
      return this.product.sale;
    },
    //
    onNew() {
      /*
      const today = new Date();
      if (this.product.new_from_date != null) {
        if (new Date(this.product.new_from_date) <= today) {
          if (this.product.new_to_date != null) {
            if (new Date(this.product.new_to_date) >= today) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
      */
      return this.product.new;
    },
    stockStatusClass() {
      return this.product.stock_status == "IN_STOCK" ? "in-stock" : "out-stock";
    },

    productImg() {
      if (this.product.thumbnail.medium) {
        return this.product.thumbnail.medium;
      }
      return this.product.thumbnail.url;
    },
    isProductInCompare() {
      return !!this.compareList.find(
        (product) => product.sku === this.product.sku
      );
    },
    productInStock() {
      return this.product.stock_status == "IN_STOCK" ? true : false;
    },
    isBackOrderAvailable() {
      return this.product.backorders_allowed;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    async onClickCompareCheckBox() {
      if (this.isProductInCompare == false) {
        await this.$store.dispatch("compare/addCompareProduct", {
          sku: this.product.sku,
        });
        this.addToComparePopup = true;
      } else {
        await this.$store.dispatch("compare/removeCompareProduct", {
          sku: this.product.sku,
        });
      }
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    clickOk() {
      this.addToComparePopup = false;
      this.$router.push("/compare");
    },
    clickCancel() {
      this.addToComparePopup = false;
      window.scrollTo(0, 0);
    },
    closeNotifyModal() {
      this.showNotifyModal = false;
    },
    ...mapActions({
      updateToggleInCompareList: "compare/updateToggleInCompareList",
    }),

    isProductInCompareList(id) {
      return this.getCompareProductList.findIndex((el) => el === id) !== -1;
    },

    addToNotify() {
      this.showNotifyModal = true;
    },
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      return items;
    },
    async addToCart() {
      if (
        this.product.__typename == "SimpleProduct" &&
        !this.isBackOrderAvailable
      ) {
        if (typeof this.product.stock_qty != "undefined") {
          let quantity = 1;
          const existingProduct = this.cartItems.find(
            (item) => this.product.sku == item.product.sku
          );
          if (existingProduct) {
            quantity += existingProduct.quantity;
          }
          if (quantity > this.product.stock_qty) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("product_not_in_stock_msg", {
                numberOf: this.product.stock_qty,
              }),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            return;
          }
        }
      }

      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' + this.product.sku + '", quantity:' + 1 + "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            const msg = {
              type: "success",
              title: this.$t("shopping_basket"),
              text: this.$t("added_product"),
            };
            if (this.$gtm.enabled()) {
              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: this.product.name,
                          id: this.product.sku,
                          quantity: this.quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems()],
                    },
                  },
                });
              }
            }
            this.$store.dispatch("messages/sendMessage", { message: msg });
            // this.$bvModal.show("modal-1");
          }
          break;
        }
        case "ConfigurableProduct":
        case "BundleProduct":
        case "GroupedProduct": {
          this.$router.push(`/${this.product.url_key}`);
          break;
        }

        default:
          break;
      }
    },
    toggleWishItem() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        if (
          this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
          true
        ) {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          if (this.product.__typename == "SimpleProduct") {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
          } else {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.$router.push("/" + this.product.url_key);
          }
        }
      } else {
        const msg = {
          type: "danger",
          title: this.$t("wishlist error"),
          text: this.$t("wishlist_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card--img-top {
  width: 100%;
  //background-size: contain;
}
.add-to-wishlist {
  position: absolute;
  left: 10px;
  top: 3px;
  font-size: 21px;
  z-index: 9;
  width: 22px;
  height: 28px;
  &:hover {
    text-decoration: none;
  }
  &:hover {
    ~ .product-card {
      color: $black !important;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    }
  }
  .added {
    color: $red;
  }
  .not-added {
    color: $borderColor;
  }
}

.product-card {
  padding: 10px 18px;
  display: block;
  height: 100%;
  transition: 0.5s;
  color: $black !important;
  &:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    text-decoration: none;
  }

  &--holder {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid $borderColor;
  }

  &--img-top {
    width: 100%;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 10px;
    position: relative;
  }

  &--product-name {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 10px;
    height: 57px;
    color: $black;
    @include truncat-3;
  }

  &--price {
    font-size: 16px;
    font-weight: 600;
  }

  &--sale-comment {
    font-size: 13px;
    font-weight: 500;
    color: $red;
    padding-top: 5px;
  }

  .actionbuttons {
    position: absolute;
    top: -10px;
    left: auto;
    right: -20px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: end;

    .sale-txt,
    .new-txt {
      background: $red;
      font-weight: 500;
      color: $white;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 4px;
    }
    .sale-txt {
      position: relative;
    }
    .new-txt {
      background: $primaryColor;
    }
    @media screen and (max-width: 560px) {
      right: -10px;
    }
  }
  @media screen and (max-width: 670px) {
    padding: 10px;
    &--img-top {
      height: 150px;
    }
  }
  @media screen and (max-width: 560px) {
    &--product-name {
      height: auto;
    }
  }
}
.available-sizes-list {
  margin: 0 -0.7rem;
  flex-grow: 1;
  flex-direction: row;
  margin-bottom: 0;

  li {
    background: $green;
    color: $white;
    font-weight: 500;
    min-width: 45px;
    height: 25px;
    margin: 0.45rem 0.7rem;
    text-align: center;
    padding: 4px 5px;
    line-height: 1;
  }
}
</style>
