<template>
  <section class="super-deals" v-if="superDealProducts.length > 0">
    <span class="super-deals--category">{{ $t("super_deal_title") }}</span>
    <div>
      <MoreProductsCarousel :products="superDealProducts.slice(0, 4)" />
    </div>
  </section>
</template>

<script>
import MoreProductsCarousel from "@/base/core/components/product-detail/MoreProductsCarousel.vue";

export default {
  name: "SuperDeals",
  components: {
    MoreProductsCarousel,
  },
  computed: {
    superDealProducts() {
      return this.$store.getters["category/getSuperDealProducts"];
    },
  },
};
</script>

<style lang="scss" scoped>





</style>
