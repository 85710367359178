<template>
  <div>
    <AdvertisingBanner class="col-12 my-30 mt-lg-0" />
    <section class="bottom-txt">
      <div class="extra-info" v-html="category.description"></div>
    </section>
    <SuperDeals />
    <CategoryPageContent />
    <section class="bottom-txt">
      <div class="extra-info" v-html="category.description"></div>
    </section>
  </div>
</template>

<script>
import CategoryPageContent from "@/esf_antarctica_feestenkleding/core/components/category-product/CategoryPageContent.vue";
import AdvertisingBanner from "@/base/core/components/category-product/AdvertisingBanner.vue";
import SuperDeals from "@/esf_antarctica_feestenkleding/core/components/category-product/SuperDeals.vue";
export default {
  name: "ProductListingV2",
  components: {
    SuperDeals,
    CategoryPageContent,
    AdvertisingBanner,
},
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
};
</script>

<style lang="scss" scoped>
h3,
.h3 {
  font-weight: 400;
  margin-bottom: 10px;
}

.bottom-txt {
  font-size: 12px;
  line-height: 1.72;
  padding-bottom: 14px;
  // padding-top: 25px;
}
</style>
